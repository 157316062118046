



















import Vue, { PropType } from 'vue';
import { FooterSection } from '../../../types/footer';

export default Vue.extend({
	props: {
		items: { type: Array as PropType<FooterSection['items']>, required: true },
	},
});
