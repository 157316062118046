import { render, staticRenderFns } from "./FooterInfo__Copyright.vue?vue&type=template&id=098c703e&"
import script from "./FooterInfo__Copyright.vue?vue&type=script&lang=ts&"
export * from "./FooterInfo__Copyright.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Link: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Link/Link.vue').default})
