
























import Vue, { PropType } from 'vue';
import { FooterSection, FooterSectionItem } from '../../../types/footer';
import { Link } from '~/types/link';

export default Vue.extend({
	props: {
		heading: { type: String, required: true },
		items: { type: Array as PropType<FooterSection['items']>, required: true },
	},
	methods: {
		filterLinkItems(items: FooterSectionItem[]): Link[] {
			return items.filter((item) => 'href' in item) as Link[];
		},
		onLinkClicked(event: Event) {
			if ((event.target as HTMLElement)?.getAttribute('href')?.includes('#cmp')) {
				this.onCmpLinkClicked(event);
			}
		},
		onCmpLinkClicked(event: Event) {
			event.preventDefault();
			event.stopPropagation();

			const cmpObject = (window as any).__cmp;

			if (!cmpObject) {
				return;
			}

			cmpObject('showGDPRScreenAdvanced');

			cmpObject(
				'addEventListener',
				[
					'consent',
					() => {
						(window as any).dataLayer?.push({ event: 'cmp.user_chose' });
						location.reload();
					},
					false,
				],
				null,
			);
		},
	},
});
