var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"tw-flex tw-flex-wrap tw-justify-center tw-items-center tw-gap-x-8 tw-gap-y-12 tw-list-none tw-p-0 sm:tw-gap-x-12 xl:tw-justify-between",attrs:{"data-name":"footer-info-services"}},_vm._l((_vm.items),function(item,index){return _c('li',{key:index},[(_vm.isExternal(item.href))?_c('a',_vm._b({attrs:{"rel":"noreferrer","target":"_blank","aria-label":item.href}},'a',{ href: item.href },false),[_c('InlineSvg',_vm._b({staticClass:"tw-max-w-full",class:{
					'tw-h-12 2xl:tw-h-16': !_vm.isBig(item.uid),
					'tw-h-10 2xl:tw-h-12': _vm.isBig(item.uid),
				}},'InlineSvg',item,false))],1):(item.href)?_c('RouterLink',{attrs:{"to":item.href,"aria-label":item.href}},[_c('InlineSvg',_vm._b({staticClass:"tw-max-w-full",class:{
					'tw-h-12 2xl:tw-h-16': !_vm.isBig(item.uid),
					'tw-h-10 2xl:tw-h-12': _vm.isBig(item.uid),
				}},'InlineSvg',item,false))],1):_c('InlineSvg',_vm._b({staticClass:"tw-max-w-full",class:{
				'tw-h-12 2xl:tw-h-16': !_vm.isBig(item.uid),
				'tw-h-10 2xl:tw-h-12': _vm.isBig(item.uid),
			}},'InlineSvg',item,false))],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }