import { PropType } from 'vue';
import { Props } from '../../types/core';
import { FooterSection } from '../../types/footer';

// Model -------------------------------------------------------------------------------------------

export interface FooterInfoModel {
	uid: string;
	paymentsData: FooterSection;
	awardsData: FooterSection;
	servicesData: FooterSection;
	socialsData: FooterSection;
	copyrightData: FooterSection;
}

// Props -------------------------------------------------------------------------------------------

export const FooterInfoProps: Props<FooterInfoModel> = {
	uid: {
		type: String as PropType<FooterInfoModel['uid']>,
		required: true,
	},
	paymentsData: {
		type: Object as PropType<FooterInfoModel['paymentsData']>,
		required: true,
	},
	awardsData: {
		type: Object as PropType<FooterInfoModel['awardsData']>,
		required: true,
	},
	servicesData: {
		type: Object as PropType<FooterInfoModel['servicesData']>,
		required: true,
	},
	socialsData: {
		type: Object as PropType<FooterInfoModel['socialsData']>,
		required: true,
	},
	copyrightData: {
		type: Object as PropType<FooterInfoModel['copyrightData']>,
		required: true,
	},
};
