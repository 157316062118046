import { render, staticRenderFns } from "./FooterInfo__Payments.vue?vue&type=template&id=1dc82633&"
import script from "./FooterInfo__Payments.vue?vue&type=script&lang=ts&"
export * from "./FooterInfo__Payments.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InlineSvg: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/InlineSvg/InlineSvg.vue').default})
