












































import Vue, { PropType } from 'vue';
import { isExternal } from '../../../assets/js/utilities/links';
import { FooterSection } from '../../../types/footer';

export default Vue.extend({
	props: {
		items: { type: Array as PropType<FooterSection['items']>, required: true },
	},
	methods: {
		isExternal(href: string): boolean {
			return isExternal(href, this.$ssrContext);
		},
		isBig(uid: string): boolean {
			return ['travel', 'club'].includes(uid);
		},
	},
});
