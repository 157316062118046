import { render, staticRenderFns } from "./FooterInfo.vue?vue&type=template&id=8547cfbe&"
import script from "./FooterInfo.ts?vue&type=script&lang=ts&"
export * from "./FooterInfo.ts?vue&type=script&lang=ts&"
import style0 from "./FooterInfo.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterInfoPayments: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/FooterInfo/partials/FooterInfo__Payments.vue').default,FooterInfoAwards: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/FooterInfo/partials/FooterInfo__Awards.vue').default,FooterInfoServices: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/FooterInfo/partials/FooterInfo__Services.vue').default,FooterInfoCopyright: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/FooterInfo/partials/FooterInfo__Copyright.vue').default,FooterInfoSocials: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/FooterInfo/partials/FooterInfo__Socials.vue').default})
