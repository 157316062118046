import Vue from 'vue';
import { InlineSvgProps } from './InlineSvg.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'InlineSvg',
	props: InlineSvgProps,
	data() {
		return {
			svg: null,
		};
	},
	computed: {
		svgTag(): any {
			return this.svg || (() => import(`../../assets/media/${this.name}.svg?inline`));
		},
	},
});
