import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface InlineSvgModel {
	uid: string;
	name: string;
	label?: string;
}

// Props -------------------------------------------------------------------------------------------

export const InlineSvgProps: Props<InlineSvgModel> = {
	uid: {
		type: String as PropType<InlineSvgModel['uid']>,
		required: true,
	},
	name: {
		type: String as PropType<InlineSvgModel['name']>,
		required: true,
	},
	label: {
		type: String as PropType<InlineSvgModel['label']>,
	},
};
