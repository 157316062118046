import { render, staticRenderFns } from "./InlineSvg.vue?vue&type=template&id=1b9fbbf2&"
import script from "./InlineSvg.ts?vue&type=script&lang=ts&"
export * from "./InlineSvg.ts?vue&type=script&lang=ts&"
import style0 from "./InlineSvg.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports